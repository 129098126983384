<template>
  <div class="row">

    <div class="col-12">
      <Card :title="`Tambah Komisi Dokter Untuk ${data.name} - Rp ${parseInt(data.price).toLocaleString('id-ID')}`">
        <template v-slot:body>
          <Form
            :form="form"
            :doctors_id="doctors_id"
            redirect="/lab-procedure/list"
          />
        </template>
      </Card>
    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from '@/component/lab-procedures/DoctorCommisionForm.vue'
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form
  },

  data() {
    return {
      // Form
      form: {
        lab_procedure_id: this.$route.params.id,
        doctors_id: [],
        hospital_income: '',
        doctor_commission: '',
        price: 0
      },          
      // Detail Data
      data: {},      
      // Other
      doctors_id: [],
    }
  },

  methods: {    

    async getData() {
      this.data = await module.get('lab-procedures/' + this.$route.params.id)
      this.form.price = parseInt(this.data.price).toLocaleString('id-ID')
      // If Data Not Found
      if (this.data == null) {
        // Redirect To List
        this.$router.push('/lab-procedure/list')
      }
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pemeriksaan Lab", },
      { title: "Harga Lab", route: "/lab-procedure/list" },
      { title: "Tambah Komisi Dokter" },
    ])
    this.getData()
  },

}
</script>